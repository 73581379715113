import React from "react";
import './Footer.scss';
import { BiLogoFacebook, BiLogoWhatsapp, BiLogoInstagram } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import LogoFooter from '../NavBar/Media-Logo/Ecu-logo.webp'
function Footer () {
    const phoneNumber = '+38349885091';
    const emailAddress = 'ecutuningservice@gmail.com';
    return (
        <div className="Footer-Container">
            <div className="Footer-Logo">
                <img data-aos="fade-left" data-aos-duration="900" src={LogoFooter} alt=""/>
                <span data-aos="fade-left" data-aos-duration="1050">Ecuchiptuning Performance</span>
            </div>
            <div className="Footer-Contact">
                <span data-aos="fade-left" data-aos-duration="900">Contact us</span>
                <a data-aos="fade-left" data-aos-duration="1050" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                <p><a data-aos="fade-left" data-aos-duration="1200" href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            </div>
            <div className="Footer-Menu"> 
            <span data-aos="fade-left" data-aos-duration="900">Menu</span>
            <Link data-aos="fade-left" data-aos-duration="1050" to='/' className='Footer-Menu-links' >
              Home
            </Link>
            <Link data-aos="fade-left" data-aos-duration="1200" to='/' className='Footer-Menu-links' >
              Services
            </Link>
            <Link data-aos="fade-left" data-aos-duration="1350" to='/' className='Footer-Menu-links' >
              Testimonals
            </Link>
            <Link data-aos="fade-left" data-aos-duration="1500" to='/' className='Footer-Menu-links' >
              About
            </Link>
            <Link data-aos="fade-left" data-aos-duration="1650" to='/' className='Footer-Menu-links' >
              Store
            </Link>
            <Link data-aos="fade-left" data-aos-duration="1800" to='/' className='Footer-Menu-links' >
              Contact
            </Link>
             </div>
            <div className="Footer-Connect">
                <span data-aos="fade-left" data-aos-duration="900">Connect with us</span>
                <div className="Footer-Icon">
                <a className='svg1' data-aos="fade-right" data-aos-duration="1950" href='https://www.facebook.com/ecuchiptuningpower' ><BiLogoFacebook/></a>
                <a className='svg2' data-aos="fade-right" data-aos-duration="2100" href='https://wa.me/38349885091' ><BiLogoWhatsapp/></a>
                <a className='svg3' data-aos="fade-right" data-aos-duration="2250" href='https://www.instagram.com/ecuchiptuningperformance'  ><BiLogoInstagram/></a>
                </div>
            </div>
        </div>
    );
}   
export default Footer;