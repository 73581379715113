import React from 'react';
import './ContactUs.scss';
import { Link } from 'react-router-dom';
import { BiLogoFacebook, BiLogoWhatsapp, BiLogoInstagram } from 'react-icons/bi';

const ContactInformation = () => {
  const phoneNumber = '+38349885091';
    const emailAddress = 'ecutuningservice@gmail.com';
    const Whatsapp = 'https://wa.me/1XXXXXXXXXX'
  return (
    <div className="ContactInfo-container">
      <div className='Blurry'>
        <div className='Contact-Information'>
        <span data-aos="fade-right" data-aos-duration="1000">Contact us</span>
                <a data-aos="fade-right" data-aos-duration="1700"  className='a-text'href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                <p data-aos="fade-right" data-aos-duration="2400"><a className='a-text' href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
                <span data-aos="fade-right" data-aos-duration="3100">Connect with us</span>
                <div className='Social-icon'>
                <a className='svg1' data-aos="fade-right" data-aos-duration="3800" href='https://www.facebook.com/ecuchiptuningpower' ><BiLogoFacebook/></a>
                <a className='svg2' data-aos="fade-right" data-aos-duration="4500" href='https://wa.me/38349885091' ><BiLogoWhatsapp/></a>
                <a className='svg3' data-aos="fade-right" data-aos-duration="5200" href='https://www.instagram.com/ecuchiptuningperformance'  ><BiLogoInstagram/></a>
                </div>
       </div>
       <div className='Contact-Map'>
       <iframe data-aos="fade-left" data-aos-duration="1700" className='Googlemapslocation' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.1097358339207!2d20.653104244290812!3d42.41077772915595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353a3b7989335fb%3A0xe14b2706a00a1443!2sECUCHIPTUNING%20Performance!5e0!3m2!1sen!2s!4v1691528327957!5m2!1sen!2s"
       style={{ border:"none",borderRadius:"25px", boxShadow:"inset 0 0 0.5px 1px hsla (0, 0%,  100%, 0.075),0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02), 0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),0 3.5px 6px hsla(0, 0%, 0%, 0.09)"}}
       ></iframe>
       </div>
       </div>
    </div>
  );
};

export default ContactInformation;
