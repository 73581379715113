import React from "react";
import '../Social/Social.scss'

function Social (){
    return (
        <div className="Social-Container">
            <div  className="left-scial-container">
            <iframe 
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fecuchiptuningpower&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=996078738312780"
             style={{ border:"none", overflow:"hidden", scrolling:"no", allowfullscreen:"true", allow:"autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"}} >
             </iframe>
                </div>
                <div className="right-scial-container">
                    <h1 data-aos="fade-left" data-aos-duration="2100">Connect with us on Facebook</h1>
                    
                    <div data-aos="fade-left" data-aos-duration="2100" className="red-line"/>
                </div>
        </div>
    );
}
export default Social;