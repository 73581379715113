import '../NavBar/NavBar.scss';
import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom';
import eculogo from './Media-Logo/Ecu-logo.webp'
import SideBar from './Mobile-navbar/SideBar';
import Backdrop from './Mobile-navbar/Backdrop';
import {GiHamburgerMenu} from 'react-icons/gi';
import {TweenMax, Power3} from 'gsap';

function NavBar (){
  
  let logoItem = useRef(null)

  useEffect(() =>{
    console.log(logoItem);
    TweenMax.to(
      logoItem,
      2,
      {
        opacity:1,
        x: 20,
        ease: Power3.easeOut
      }
    )
  })
  const [sideDraw, setsideDraw] = useState(false);
  const onButton = () => {
    setsideDraw((prev) => !prev);
  }
  useEffect(() => {
    const handleTouchMove = (event) => {
      if (sideDraw) {
        event.preventDefault();
      }
    };
    if (sideDraw) {
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    // Remove event listener when the component unmounts or sidebar is closed
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [sideDraw]);

    return (
        <div  className="navbar">
      <div data-aos="fade-right" data-aos-duration="1000" className="logo"> <img    ref={el => {logoItem = el}} className='Logo-Image' src={eculogo} alt="ECU-LOGO" /> </div>
     <div className='NavBar-Links'>
      <Link data-aos="fade-left" data-aos-duration="1000"  to='/' className='NavBar-Home-Button' >
        Home
        <div className='Navbar-Links-Border-Button1'/>
      </Link>
      <Link data-aos="fade-left" data-aos-duration="1750" to='/ContactUs' className='NavBar-Contact-Button' >
        Contact
        <div className='Navbar-Links-Border-Button6'/>
      </Link>
     </div>
     <GiHamburgerMenu className='Menu-Button'  onClick={onButton}/>
                {
                    sideDraw && <SideBar onClose={onButton} />

                }
                {
                    sideDraw && <Backdrop onClose={onButton} />

                }
    </div>
    );
}
export default NavBar;