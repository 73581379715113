import './App.scss';
import React from 'react';
import 'aos/dist/aos.css';
import Footer from './Footer/Footer';
import Specifications from './Specifications/Specifications';
import Ourwork from './Ourwork/Ourwork';
import Tunning from './Features/Tunning';
import Social from './Social/Social';
import Banner from './Banner/Banner';
import NavBar from './NavBar/NavBar'
function Apps() {

  return (
    <div className="App"  >
      <NavBar  />
      <Banner/>
      <Specifications/>
      <Tunning className="tuning" />
      <Social/>
      <Ourwork/>
      <div className='Red-line'/>
      <Footer/>
    </div>
  );
}

export default Apps;
