import React from 'react'
import { Link } from 'react-router-dom'
import './SideBar.scss';
import {AiOutlineClose} from 'react-icons/ai';
export default function SideBar(props) {
  return (
    <div data-aos="fade-left" data-aos-duration="200" className='Side-Drawer'>
      <div data-aos="fade-left" className='Top-Menu-Bar'>
        <AiOutlineClose className='Close-Icon-SideBar' onClick={props.onClose} />
      </div>
      <div data-aos="fade-left" className='Bottom-Menu-bar'>
      <Link to='/' >
        Home
      </Link>
      <Link to='/ContactUs' >
        Contact
      </Link>
      </div>
    </div>
  )
}
