import React from "react";
import VW from "./Media/vw.png"
import BMW from "./Media/bmw.png"
import AR from "./Media/alfaromeo.png"
import AM from "./Media/astonmartin.png"
import AD from "./Media/Audi.png"
import CT from "./Media/citoren.png"
import FD from "./Media/ford.png"
import LB from "./Media/lamborghini.png"
import MD from "./Media/mercedes.png"
import PS from "./Media/porsche.png"
import MC from "./Media/minicooper.png"
import ST from "./Media/seat.png"
import './ourwork.scss';
function Ourwork (){
    return (
        <div className="Ourwork-container">
            <div className="Ourwork-Upper-container">
                <h1>We support all major manufacturers including:</h1>
                <div className="red-line"/>
            </div>
            <div className="Ourwork-Bottom-Container">
                <div className="Row1-Logo-Car">
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={BMW} alt="BMW" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={VW} alt="VW" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={MD} alt="MD" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo1' src={AD} alt="AD" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={FD} alt="FD" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={AR} alt="AR" />
            </div>
            <div className="Row2-Logo-Car">
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={CT} alt="CT" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={LB} alt="LB" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={AM} alt="AM" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo1' src={MC} alt="MC" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={ST} alt="ST" />
            <img data-aos="fade-up" data-aos-duration="2100" className='Car-Logo' src={PS} alt="PS" />
            </div>
            </div>
        </div>
    );
}
export default Ourwork