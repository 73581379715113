import React from 'react';
import './ContactUs.scss';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import ContactInformation from './Contactinformation';


const ContactUs = () => {
  return (
    <div className="ContactUs-container">
       <NavBar/>
       <ContactInformation/>
       <div className='red-line1'/>
       <Footer/>
       <div className='red-line3'/>
    </div>
  );
};

export default ContactUs;
