import React from "react";
import "../Specifications/Specifications.scss"
import {BsRocket, BsSpeedometer2, BsTools} from 'react-icons/bs';

export default function Specifications() {
    const valuesData = [
        {
          title:"Performance Remaps",
          titletext:"If you're after sheer performance we'll remap your vehicle to get the maximum BHP and torque.",
          icon:<BsRocket/>,
        },
        {
            title:"Fuel Economy Remaps",
            titletext:"We'll remap your car to provide better economy, this will save you money and can also increase performance.",
            icon:<BsSpeedometer2/>,
        },
        {
            title:"Additional Options",
            titletext:"We are also able to do DPF removal, EGR removal, speed limit removal and rev limit removal.",
            icon:<BsTools/>,
          
          }
      ]
  return (

    <div className='Eevent-Container'>
      {valuesData.map((props,i) => {
            return(
                  <div data-aos="fade-up" data-aos-duration="1000"  className='Specifications-Card'>
                    <p data-aos="fade-up" data-aos-duration="1100" className='Icon-Card'>{props.icon}</p> 
                    <p data-aos="fade-up" data-aos-duration="1200" className='Title-Card'>{props.title}</p>
                    <span data-aos="fade-up" data-aos-duration="1300" className="tittletext-Card">{props.titletext}</span>
                  </div>
            )
          })}
    </div>
  )
}