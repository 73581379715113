import './App.scss';
import React, {useEffect, useState} from 'react';
import ContactUs from "./ContactUs/ContactUs"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Loading from './Banner/Loading/Loading';
import Apps from './Apps';
function Home() {
  useEffect(() => {
    Aos.init({
      duration:800,
      once: true
    })
  }, [])
  const [isLoading, setIsLoading] = useState(true);
  const [loadedMeetups, setLoadedMeetups] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch(
      'https://react-getting-started-48dec-default-rtdb.firebaseio.com/meetups.json'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const meetups = [];

        for (const key in data) {
          const meetup = {
            id: key,
            ...data[key]
          };

          meetups.push(meetup);
        }

        setIsLoading(false);
        setLoadedMeetups(meetups);
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Loading/>
      </section>
    );
  }
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Apps/>} />
      <Route path='/ContactUs' element={<ContactUs/>} />
      </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default Home;
