import React from 'react';
import './tuning.scss';
import IframeResizer from 'iframe-resizer-react' 

function tuning () {
  return (
    <div>
<IframeResizer src="https://tuning-shop.com/iframe/iframe.php?user=816" 
heightCalculationMethod="lowestElement"
inPageLinks
log
style={{
  width:"100%",border:"none", height:"600", scrolling:"yes", frameborder:"0", style:"border: 0;"
}}></IframeResizer>
</div>
   );
};

export default tuning;
