import React, { useState, useEffect } from 'react';
import './Banner.scss';
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import {BsChevronRight} from 'react-icons/bs'
import {BsChevronLeft} from 'react-icons/bs'

const images = [image1, image2, image3]; // Import images using require or import

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
    }, 5000);

    return () => clearInterval(interval);
  }, [totalImages]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <div className="slideshow-container">
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      <div className="button-container">
        <button onClick={handlePrev}><BsChevronLeft/></button>
        <button onClick={handleNext}><BsChevronRight/></button>
      </div>
      <div class="overlay"></div>
    </div>
  );
};

export default Banner;
